import React, { useMemo } from "react";
import { Row, Col, Container } from "reactstrap";

import Layout from "@simplur/gatsby-theme-full-site/src/components/layout";
import SEO from "@simplur/gatsby-theme-full-site/src/components/seo";
import BreadCumps from "@simplur/gatsby-theme-full-site/src/components/BreadCumps";
import PCCard from "../../components/ProductCategoryCard.tsx";
import { useThemeOption } from "@simplur/gatsby-theme-full-site/src/hooks";

const ProductCategoryCard = ({ nodes }) => {
  const categories = useMemo(() => nodes.filter((cat) => cat), [nodes]);

  return (
    <Row lg="5">
      {categories.map((cat) => (
        <Col key={cat.id} className="d-flex h-auto mb-4">
          <PCCard category={cat} />
        </Col>
      ))}
    </Row>
  );
};

const ListTitle = ({ children }) => {
  return (
    <Row className="mb-3">
      <Col>
        <h1 className="section__title">{children}</h1>
      </Col>
    </Row>
  );
};

const ProductCategoryArchive = ({ pageContext }) => {
  const { brandFullName, productBaseSlug } = useThemeOption();

  let pathName = "";
  if (typeof window !== "undefined") {
    pathName = window.location.pathname;
  }

  let basePath = pathName;
  if (basePath.startsWith("/")) {
    basePath = basePath.substring(1);
  }

  if (basePath.endsWith("/")) {
    basePath = basePath.substring(0, basePath.length - 1);
  }

  const {
    category,
    categories = [],
    breadCrump = [],
    // parentCategories,
    // products,
    // flatCategories,
    // pagination,
    brandCategories = [],
  } = pageContext;

  let slugToUse = category?.slug;
  if (!slugToUse || slugToUse === productBaseSlug) {
    slugToUse = "all";
  }

  if (typeof window === "undefined") {
    return <></>;
  }

  const showBrandCategory = brandCategories.length > 0;

  // const { AnimateOnChange } = require("react-animation")
  return (
    <Layout classNames="archive">
      {category ? (
        <SEO title={category.name} json={category.seo} />
      ) : (
        <SEO
          title="Our Categories"
          description={`Welcome to ${brandFullName}`}
        />
      )}
      <Container>
        {breadCrump ? (
          <BreadCumps path={pathName} breadCrump={breadCrump} />
        ) : null}
      </Container>
      <Container>
        <Row className="my-3">
          <Col>
            <h1 className="section__title">
              {category
                ? category.slug === "sewing-machines"
                  ? "Shop by Machine Type"
                  : category.name
                : "PRODUCT CATEGORIES"}
            </h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <ProductCategoryCard nodes={categories} />
            {showBrandCategory && (
              <div>
                <ListTitle>Shop by Brand</ListTitle>
                <ProductCategoryCard nodes={brandCategories} />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ProductCategoryArchive;
