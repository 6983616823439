import React from "react";
import { Link } from "gatsby";
import { Col, Card, CardTitle, CardBody, CardImg } from "reactstrap";

import { makeTitle } from "@simplur/gatsby-theme-full-site/src/utils/formatters";

type Image = {
  altText: string;
  uri: string;
  sourceUrl: string;
  srcSet: string;
};

type CategoryType = {
  id: string;
  name: string;
  slug: string;
  image: Image;
  uri: string;
};

type ProductCategoryCardPropType = {
  category: CategoryType;
};

const ProductCategoryCard = ({ category }: ProductCategoryCardPropType) => {
  const splitStr = window.location.pathname.split("/");
  const slug = splitStr.filter((str) => str).slice(-1);

  const isBrandCategory = slug.includes("brands");

  return (
    <Card className="h-100 w-100 d-flex flex-column shadow text-center">
      <Link
        to={category.uri}
        className={!isBrandCategory ? "partial-border" : ""}
      >
        <div className="card-image-wrapper">
          <CardImg
            top
            src={category.image?.sourceUrl}
            srcSet={category.image?.srcSet}
            alt={category.image?.altText}
            className="p-2"
          />
        </div>
      </Link>

      {!isBrandCategory ? (
        <CardBody className="d-flex flex-column-reverse">
          {
            <Link to={category.uri}>
              <CardTitle>
                <h6 className="line-clamp">
                  {makeTitle(category.name)}
                </h6>
              </CardTitle>
            </Link>
          }
        </CardBody>
      ) : null}
    </Card>
  );
};

export default ProductCategoryCard;
